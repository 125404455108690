import React from 'react';
import { images } from '../../../../constants/Images';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import config from '../../../../constants/config';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import { i18n } from '../../../../services';
import { Filter } from '../../../../utils';
import { IHomeknowyourtireData } from '../../../../models/IHomeState';

interface IProps {
   items: IHomeknowyourtireData[]
   goToBlogDetail: (item: IHomeknowyourtireData) => void;
}

class Blogs extends React.Component<IProps> {

   renderItem = () => {
      const {items, goToBlogDetail } = this.props
      if(items)
      return items.map((item: IHomeknowyourtireData, index) => {
         console.log('items', `${config.imageBaseUrl}${item.path}`);
         return (
            <div style={{cursor: 'pointer'}} className="col-12 col-sm-8 col-md-6 col-lg-4 px-2" key={index} onClick={() => goToBlogDetail(item)}>
            <div className="card">
             <img className="card-img blog-2" src={`${config.imageBaseUrl}${item.path}`} alt="" />
             <div className="card-body">
               {/* <span className="date">13 August 2020</span> */}
               <h4 className="card-title">{Filter.translate(item.page_title, item.title_arebic)}</h4>          
         {/* <p className="card-text">{ReactHtmlParser(Filter.translate(item.content.substr(0, 200), item.content_arebic.substr(0, 200)) )}</p> */}
            </div>
         </div>
         </div>
         )
      })

   }

    render() {
      return (
         <section className="blogs-section">
         <div className="container px-5">
          <div className="row">
            <div className="col-lg-12 mb-5 text-center">
               <div className="main-heading">
                  <h6>{i18n.t(`home.blog.title`)}</h6>
                  <h2>{i18n.t(`home.blog.subtitle`)}</h2>
                  <p>{ReactHtmlParser(i18n.t(`home.blog.description`))}</p>
               </div>
            </div>
         </div>
      
         <div className="row">
    
         {this.renderItem()}
    
      </div>
      
      </div>
      </section> 
      )
    }
}

export default withTranslation()<any>(Blogs)